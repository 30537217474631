import { Injectable } from '@angular/core';
import { StoreModel } from 'src/app/core/domain/store.model';
import { YOUCAN_INTEGRATION_URL, ZID_INTEGRATION_URL } from 'src/app/presentation/shared/constants';

const STORES_DATA: StoreModel[] = [
  {
    storeName: 'STORES.YOUCAN.NAME',
    storeDescription: 'STORES.YOUCAN.DESCRIPTION',
    storeImage: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/youcan.svg',
    provider: 'youcan',
    redirectUri: YOUCAN_INTEGRATION_URL,
    howToLinkVideo: 'STORES.YOUCAN.HOW_TO_LINK',
  },
  {
    storeName: 'STORES.ZID.NAME',
    storeDescription: 'STORES.ZID.DESCRIPTION',
    storeImage: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/zid.svg',
    provider: 'zid',
    redirectUri: ZID_INTEGRATION_URL,
  },
];

@Injectable({
  providedIn: 'root',
})
export class StoresLocalDataSource {
  getStores(): StoreModel[] {
    return STORES_DATA;
  }
}
