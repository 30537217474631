enum OrderSource {
  CART = 'Cart',
  EASY_ORDERS = 'Easy_Orders',
  YOUCAN = 'YOUCAN',
  ZID = 'ZID',
  BULK_UPLOAD = 'bulk_upload',
  DUKAN = 'dukan',
  ANDROID = 'android',
  IOS = 'ios',
  LEAD = 'lead',
  TELESALES = 'telesales',
  WOOCOMMERCE = 'woocommerce',
  SHAHBNDR = 'shahbndr',
}

export const FAILED_ORDERS_SOURCE_MAP = new Map<string, string>([
  [OrderSource.CART, 'FAILED_ORDERS.SOURCES.CART'],
  [OrderSource.EASY_ORDERS, 'FAILED_ORDERS.SOURCES.EASY_ORDERS'],
  [OrderSource.YOUCAN, 'FAILED_ORDERS.SOURCES.YOUCAN'],
  [OrderSource.ZID, 'FAILED_ORDERS.SOURCES.ZID'],
  [OrderSource.ANDROID, 'FAILED_ORDERS.SOURCES.ANDROID'],
  [OrderSource.BULK_UPLOAD, 'FAILED_ORDERS.SOURCES.BULK_UPLOAD'],
  [OrderSource.DUKAN, 'FAILED_ORDERS.SOURCES.DUKAN'],
  [OrderSource.IOS, 'FAILED_ORDERS.SOURCES.IOS'],
  [OrderSource.LEAD, 'FAILED_ORDERS.SOURCES.LEAD'],
  [OrderSource.TELESALES, 'FAILED_ORDERS.SOURCES.TELESALES'],
  [OrderSource.WOOCOMMERCE, 'FAILED_ORDERS.SOURCES.WOOCOMMERCE'],
  [OrderSource.SHAHBNDR, 'FAILED_ORDERS.SOURCES.SHAHBNDR'],
]);
