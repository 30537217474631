import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import packageInfo from 'package.json';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { PhoneDataModel } from 'src/app/core/domain/auth/auth.model';
import { UpdateProfileModel } from 'src/app/core/domain/auth/update-profile.model';
import { MerchantStore } from 'src/app/core/domain/merchant-store.model';
import { OrderSummaryModel } from 'src/app/core/domain/order-summary.model';
import { ReferralsModel } from 'src/app/core/domain/referrals.model';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { UserRepository } from 'src/app/core/repositories/user.repository';
import { INCENTIVE_PROGRAM_FEATURE } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { ProfileService } from 'src/app/presentation/shared/services/profile.service';
import { OrderSummaryMapper } from '../auth/mappers/order-summary.mapper';
import { ReferralsMapper } from '../auth/mappers/referrals-mapper';
import { OrderApisService } from '../order/order-apis.service';
import { UserCache } from './local/user-cache';
import { UserEntity } from './remote/entities/user.entity';
import { UserApiService } from './remote/user-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserImplRepository extends UserRepository {
  private _userObject;

  private _orderSummaryMapper: OrderSummaryMapper;

  private _referralsMapper = new ReferralsMapper();

  constructor(
    private _userApiService: UserApiService,
    private _userCache: UserCache,
    private _localStorageService: LocalStorageService,
    private _orderApisService: OrderApisService,
    private _profileService: ProfileService,
  ) {
    super();
    this._userObject = this._localStorageService.getUser();
    this._orderSummaryMapper = new OrderSummaryMapper();
  }

  public getPhoneData(): PhoneDataModel {
    return {
      phoneNumber: this.getUser().phoneNumber!,
      callingCode: this.getUser().callingCode!,
    };
  }

  getUserSuperProperties() {
    const userData = this.getUser();
    return {
      ...userData,
      version: packageInfo.version,
      is_multitenancy_enabled: true,
      is_incentive_program_enabled: !!userData?.features?.includes(INCENTIVE_PROGRAM_FEATURE),
      ui_session_key: user.uiSessionKey,
      selected_country: country.code,
    };
  }

  updateSwitchedSelectedMarket(selectedMarket: string): Observable<void> {
    return this._userApiService.updateSwitchedSelectedMarket(selectedMarket);
  }

  getOrderSummary(startDate: string, endDate: string): Observable<OrderSummaryModel> {
    return this._orderApisService
      .getOrderSummary(startDate, endDate)
      .pipe(map((res) => this._orderSummaryMapper.mapFrom(res.data)));
  }

  getUser(): UserModel {
    return this._userCache.get();
  }

  getTaagerId(): string {
    return this.getUser()?.taagerId ? this.getUser()?.taagerId?.toString() : '';
  }

  setUser(userData: UserModel): void {
    this._userObject = userData;
    this._userCache.put(userData);
  }

  setUserStores(stores: MerchantStore[]): void {
    this.setUser({ ...this._userObject, stores });
  }

  updateProfilePicture(file: File, fileName?: string): Observable<string> {
    const profilePictureData = new FormData();
    profilePictureData.set('image', file, fileName);
    return this._userApiService.updatePicture(profilePictureData);
  }

  getReferrals(): Observable<ReferralsModel | Error> {
    return this._orderApisService.getReferralsOrders().pipe(
      map((referrals) => this._referralsMapper.mapFrom(referrals)),
      catchError((err) => throwError(() => new Error(''))),
    );
  }

  updateProfile(form: UpdateProfileModel): Observable<void> {
    return this._userApiService.updateUserProfile(form).pipe(
      tap(() => {
        this._profileService.clearProfileCache();
      }),
    );
  }

  checkUserFeatureExists(feature: string): boolean {
    return user?.features.includes(feature);
  }

  deleteAccount(password: string): Observable<void> {
    return this._userApiService.deleteAccount(password);
  }

  getUserProfile(): Observable<{ data: UserEntity }> {
    return this._userApiService.getUserProfile();
  }
}
