import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { AUTH_URL, REGISTER_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { isUserLoggedIn, stringToUrlTree, userHasFullySignedUp } from './shared';

/**
 *
 * @param config
 *
 * As a user, I may want to access the cart, for me to be able to access it, I must meet the
 * below requirements:
 *
 * 1. Be logged in
 * 2. Be fully signed up
 */

export const cartResolver = (): Observable<RouteAccessResolutionTypes> => {
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
            next: (hasFullySignedUp) => {
              if (hasFullySignedUp) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(REGISTER_URL, router));
              }
            },
          });
        } else {
          subscriber.next(stringToUrlTree(AUTH_URL, router));
        }
      },
    });
  });
};
