import { Mapper } from 'src/app/core/base/mapper';
import { OrderSummaryModel } from 'src/app/core/domain/order-summary.model';
import { OrderSummaryEntity } from '../remote/entities/auth-entity';

export class OrderSummaryMapper extends Mapper<OrderSummaryEntity, OrderSummaryModel> {
  mapFrom(param: OrderSummaryEntity): OrderSummaryModel {
    return {
      placedOrders: param.total,
      confirmedOrders: param.confirmed,
      deliveriesInProgress: param.deliveryInProgress,
      suspendedOrders: param.suspended,
      deliveredOrders: param.delivered,
      refundedOrders: param.deliveredWithRefunds,
      deliveredPercentage: parseInt(param.deliveredPercentage.toString(), 10),
      totalExcludeCancelled: param.totalExcludeCancelled,
    };
  }

  mapTo(param: OrderSummaryModel): OrderSummaryEntity {
    throw Error('TODO if needed');
  }
}
