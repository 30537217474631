import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, shareReplay, tap } from 'rxjs';
import {
  APIKeyGenerateModel,
  APIKeysGetModel,
} from 'src/app/core/domain/api-keys-integration.model';
import { MerchantStore, MerchantStoreAuth } from 'src/app/core/domain/merchant-store.model';
import { StoreModel } from 'src/app/core/domain/store.model';
import { UserStoreModel } from 'src/app/core/domain/user-store.model';
import { MerchantStoreRepository } from 'src/app/core/repositories/merchant-store.repository';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import {
  FEATURE_FLAGS,
  YOUCAN_PROVIDER_NAME,
  ZID_PROVIDER_NAME,
} from 'src/app/presentation/shared/constants';
import { MerchantStoreApiService } from '../../api/merchant-store-api.service';
import { UserStoreMapper } from './mappers/user-store.mapper';

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreRepositoryImplementation implements MerchantStoreRepository {
  private _userStoreMapper = new UserStoreMapper();

  private _cachedStores$: Observable<UserStoreModel[]> | null;

  constructor(
    private merchantStoreApi: MerchantStoreApiService,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
  ) {}

  generateAPIKey(expiresAt: string): Observable<APIKeyGenerateModel> {
    return this.merchantStoreApi.generateAPIKey(expiresAt);
  }

  getAPIKeys(): Observable<APIKeysGetModel[]> {
    return this.merchantStoreApi.getAPIKeys();
  }

  linkStore(storeAuth: MerchantStoreAuth): Observable<MerchantStore> {
    return this.merchantStoreApi.linkStore(storeAuth).pipe(
      tap(() => {
        this._cachedStores$ = null;
      }),
    );
  }

  unlinkStore(storeId: string): Observable<void> {
    return this.merchantStoreApi.unlinkStore(storeId).pipe(
      tap(() => {
        this._cachedStores$ = null;
      }),
    );
  }

  addProductToStore(merchantStore: MerchantStore, productId: string): Observable<null> {
    return this.merchantStoreApi.addProductToStore(merchantStore, productId);
  }

  getStores(): Observable<StoreModel[]> {
    return this.merchantStoreApi.getStores();
  }

  getUserStores(): Observable<UserStoreModel[]> {
    if (!this._cachedStores$) {
      this._cachedStores$ = combineLatest({
        userStores: this.merchantStoreApi.getUserStores(),
        youcanFlag: this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.YOUCAN_STORE),
        zidFlag: this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.ZID_INTEGRATION),
      }).pipe(
        map(({ userStores, youcanFlag, zidFlag }) => {
          const mappedStores = userStores.map(this._userStoreMapper.mapFrom);
          return mappedStores.filter(
            (store) =>
              (youcanFlag && store.provider === YOUCAN_PROVIDER_NAME) ||
              (zidFlag && store.provider === ZID_PROVIDER_NAME),
          );
        }),
        shareReplay(1),
      );
    }
    return this._cachedStores$;
  }
}
