import { Injectable } from '@angular/core';
import { mapUserData, user } from '@features/user/data';
import cache from '@mongez/cache';
import { navigateTo } from '@presentation/shared/router';
import { setCurrentCacheVersion } from '@presentation/shared/utils';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { BehaviorSubject, catchError, map, mergeMap, Observable, tap, throwError } from 'rxjs';
import {
  AuthModel,
  EmailOTPDataModel,
  EmailOTPVerifyModel,
  InitialSignupUserModel,
  LoginCredentialsModel,
  OTPDataModel,
  OTPModel,
  PhoneDataModel,
  UserInfoModel,
} from 'src/app/core/domain/auth/auth.model';
import { ChangePasswordFormModel } from 'src/app/core/domain/auth/change-password.model';
import { ForgotPasswordFormModel } from 'src/app/core/domain/auth/forgot-password.model';
import { ResetPasswordModel } from 'src/app/core/domain/auth/reset-password.model';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { AuthRepository } from 'src/app/core/repositories/auth.repository';
import {
  CUSTOMER_EFFORT_SCORE,
  LOCAL_STORAGE_ONBOARDING,
  LOGIN_URL,
  REDIRECT_URL,
  TAMARA_PREFERENCE_KEY,
} from 'src/app/presentation/shared/constants';
import {
  CART_BANNER_KEY,
  CART_DIALOG_KEY,
  CATALOG_BANNER_KEY,
} from 'src/app/presentation/shared/constants/quantity-discount';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { LEARNING_STORAGE_KEY } from '../learning/local/learning-cache';
import { UserCache } from '../user/local/user-cache';
import { UserInfoMapper } from '../user/mappers/user-info.mapper';
import { UserMapper } from '../user/mappers/user-mapper';
import { OTPMapper } from '../user/mappers/user-otp.mapper';
import { AuthMapper } from './mappers/auth.mapper';
import { LoginCredentialsMapper } from './mappers/credentials.mapper';
import { ResetPasswordMapper } from './mappers/reset-password.mapper';
import { SocialLoginMapper } from './mappers/social-login-mapper';
import { AuthApiService } from './remote/auth-api.service';
import { LoginResponse } from './remote/entities/login.entity';

@Injectable({
  providedIn: 'root',
})
export class AuthRepositoryImplementation implements AuthRepository {
  private _authMapper = new AuthMapper();

  private otpMapper = new OTPMapper();

  private _userMapper = new UserMapper();

  private userInfoMapper = new UserInfoMapper();

  private _socialLoginMapper = new SocialLoginMapper();

  private _resetPasswordMapper = new ResetPasswordMapper();

  private _credentialsMapper = new LoginCredentialsMapper();

  private _currentRegisterStatusToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private _authApiService: AuthApiService,
    private _socialAuthService: SocialAuthService,
    private _userCache: UserCache,
    private _localStorageService: LocalStorageService,
  ) {}

  login(data: LoginCredentialsModel): Observable<void> {
    return this._authApiService.login(this._credentialsMapper.mapTo(data)).pipe(
      tap((res: LoginResponse) => {
        this.setCurrentRegisterToken(res.jwtToken);
        this.setUser(this._userMapper.mapFrom(res));

        user.login(mapUserData(res));
      }),
      map(() => undefined),
    );
  }

  googleLogin(): Observable<void> {
    return this._socialAuthService.initState.pipe(
      mergeMap(() => this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)),
      catchError(() => throwError(new Error('حدث خطأ في الاتصال، من فضلك حاول مرة أخرى'))),
      mergeMap(() => this._socialAuthService.authState),
      mergeMap((data) =>
        this._authApiService.socialAuthSignIn(data).pipe(
          tap((res) => {
            this.setCurrentRegisterToken(res.data);
            this.setUser(this._socialLoginMapper.mapFrom(res));

            user.login(
              mapUserData({
                token: res.data,
                ...res.user,
              }),
            );
          }),
          map(() => undefined),
        ),
      ),
    );
  }

  register(data: InitialSignupUserModel): Observable<AuthModel> {
    return this._authApiService.register(data).pipe(
      map(this._authMapper.mapFrom),
      tap((res: AuthModel) => {
        this.setUser({
          taagerId: res.taagerId,
          selectedMarket: res.selectedMarket,
          email: res.email,
          username: res.email,
          callingCode: res.callingCode,
          phoneNumber: res.phoneNumber,
          verificationState: res.verificationState,
          actualVerificationState: res.actualVerificationState,
          token: res.token,
        });
        user.login(mapUserData(res));
      }),
    );
  }

  requestOTP(phoneData: PhoneDataModel): Observable<OTPDataModel> {
    return this._authApiService.requestOTP(phoneData).pipe(
      tap(() => {
        const userObject = this._userCache.get();
        if (phoneData.phoneNumber !== userObject.phoneNumber) {
          const newUser = {
            ...userObject,
            phoneNumber: phoneData.phoneNumber,
          };
          this.setUser(newUser);

          user.update(mapUserData(newUser));
        }
      }),
    );
  }

  requestEmailOTP(context: 'login' | 'withdrawal'): Observable<EmailOTPDataModel> {
    return this._authApiService.requestEmailOTP(context);
  }

  verifyPhoneNumber(userOTP: OTPModel): Observable<AuthModel> {
    return this._authApiService.verifyPhoneNumber(this.otpMapper.mapTo(userOTP)).pipe(
      map(this._authMapper.mapFrom),
      tap((res: AuthModel) => {
        const data = { ...this._userCache.get(), ...res };

        user.login(mapUserData(res));

        this.setUser(data);
      }),
    );
  }

  verifyEmailOTP(userOTP: EmailOTPVerifyModel): Observable<AuthModel> {
    return this._authApiService.verifyEmailOTP(userOTP).pipe(
      map(this._authMapper.mapFrom),
      tap((res: AuthModel) => {
        const data = { ...this._userCache.get(), ...res };

        user.login(mapUserData(res));

        this.setUser(data);
      }),
    );
  }

  completeUserProfile(userInfo: UserInfoModel): Observable<AuthModel> {
    return this._authApiService.completeUserProfile(this.userInfoMapper.mapTo(userInfo)).pipe(
      map(this._authMapper.mapFrom),
      tap((res) => {
        const data = {
          ...this._userCache.get(),
          ...res,
        };

        // because complete profile returns a new JWT, then we need to update the token in the cache
        user.login(mapUserData(res));

        this.setUser(data);
      }),
    );
  }

  // TODO: Do we need all this?
  logout(): void {
    const backup = this.keepStorage();
    user.logout();
    try {
      this._localStorageService.empty();
    } finally {
      // keep current version in local storage
      this.restore(backup);
      setCurrentCacheVersion();
      navigateTo(LOGIN_URL);
    }
  }

  forgotPassword(form: ForgotPasswordFormModel): Observable<void> {
    return this._authApiService.forgotPassword(form);
  }

  resetPassword(request: ResetPasswordModel): Observable<void> {
    return this._authApiService.resetPassword(this._resetPasswordMapper.mapTo(request));
  }

  changePassword(form: ChangePasswordFormModel): Observable<void> {
    return this._authApiService.changePassword(form);
  }

  private keepStorage(): Map<string, any> {
    return new Map([
      [REDIRECT_URL, cache.get(REDIRECT_URL)],
      [LEARNING_STORAGE_KEY, this._localStorageService.getStorage(LEARNING_STORAGE_KEY)],
      [LOCAL_STORAGE_ONBOARDING, this._localStorageService.getStorage(LOCAL_STORAGE_ONBOARDING)],
      [CART_BANNER_KEY, this._localStorageService.getStorage(CART_BANNER_KEY)],
      [CART_DIALOG_KEY, this._localStorageService.getStorage(CART_DIALOG_KEY)],
      [CATALOG_BANNER_KEY, this._localStorageService.getStorage(CATALOG_BANNER_KEY)],
      [CUSTOMER_EFFORT_SCORE, this._localStorageService.getStorage(CUSTOMER_EFFORT_SCORE)],
      [TAMARA_PREFERENCE_KEY, this._localStorageService.getStorage(TAMARA_PREFERENCE_KEY)],
    ]);
  }

  private restore(backup: Map<string, any>): void {
    backup.forEach((value, key) =>
      this._localStorageService.setStorage(key, value === false ? value.toString() : value),
    );
  }

  // TODO: Why not use token cache?!
  // TODO: set shouldn't be public
  setCurrentRegisterToken(token: string): void {
    this._currentRegisterStatusToken$.next(token);
  }

  getCurrentRegisterToken(): Observable<string> {
    return this._currentRegisterStatusToken$;
  }

  private setUser(data: UserModel): void {
    this._userCache.put(data);
  }
}
