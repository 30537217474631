export const environment = {
  production: true,
  BACKEND_URL: 'https://merchant.api.taager.com/',
  BACKEND_URL_API: 'https://merchant.api.taager.com/api',
    CUSTOMER_ORDER_URL: 'https://damenkom-orders.com?id=',
  HAZELNUT_API_KEY: 'ggW9DclMzksUloOlO5Cw6PFFX3gaD2XmSBEmNL762u4pgfv2e5w7ZNvptDOFYWAn',
  ENABLE_MAIL_SEND: true,
  HIGH_QTY: 60,
  LOW_QTY: 30,
  GOOGLE_APP_ID: '509244296721-bv7om12jrpctk13eq3ik1f6ct77h4599.apps.googleusercontent.com',
  FACEBOOK_APP_ID: '346947376618392',
  MIXPANEL_PROJECT_TOKEN: 'ed3716c72491f5a77f1cd3b87ec79b67',
  MIXPANEL_PROXY_DOMAIN: 'https://business.api.taager.com/',
  FRESH_CHAT_TOKEN: 'f03fbaa5-d873-44f8-bf08-346bf5913bb8',
  RECAPTCHA_BYPASS: false,
  RECAPTCHA_BYPASS_RESPONSE: '',
  DREEVO_CLIENT_CODE: 'DV1375',
  firebase: {
    apiKey: 'AIzaSyBwfXbN6zpCsdZBUOXDFfLvcG1OyY0pWqE',
    authDomain: 'taager-merchant-app.firebaseapp.com',
    databaseURL: 'https://taager-merchant-app-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'taager-merchant-app',
    storageBucket: 'taager-merchant-app.appspot.com',
    messagingSenderId: '289739124282',
    appId: '1:289739124282:web:19bd97bcbf3d9a31ac0f03',
    measurementId: 'G-87955SL50L',
  },
  versionCheckURL: 'version.json',
  zendeskSecretKey:
    'prKHpj7xISuMy3HCbTmURZRKNG9Kye6gL5Qn7lPQN9HtZ8mZufBf6notB78MwUDy5lj97v_Cy4kp7k5LSQjxPw',
  IMAGES_BUCKET_URL: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/',
  ZID_CLIENT_ID: '1321',
  CUSTOMER_IO_SITE_ID: '42162342f77d11979079',
  CUSTOMER_IO_ORGANIZATION_ID: '089c188c-3031-491b-8b02-fff7ba350230',
  RECAPTCHA_SITE_KEY: '6LfXhPEjAAAAAMjfPCr0PoWNLh0H6P27QClK7oCa',
  ZAPIER_WEBHOOK_URL: 'https://hooks.zapier.com/hooks/catch/14335452/bv3s0kv/',
  ZID_AUTHORIZE_URL:
    'https://oauth.zid.sa/oauth/authorize?client_id=1321&redirect_uri=https://taager.com/zid-integration&response_type=code',
  YOUCAN_AUTHORIZE_URL:
    'https://seller-area.youcan.shop/admin/oauth/authorize?client_id=11&redirect_uri=https://taager.com/youcan-integration&response_type=code&scope=*',
  datadog: {
    applicationId: '9e26bfd8-f665-434b-9c54-b19702c54c21',
    clientToken: 'pub8aa3a5da2f94c28560f59ea1c4b7dea5',
    service: 'merchant-frontend',
    env: 'production',
  },
  APP_ID: '518032bd-2b92-4ca8-a75d-9b0a7cdad018',
  APP_KEY: '8daca8ee-5e49-4279-a7c9-c75230da00cf',
  Domain: 'msdk.freshchat.com',
  CREATIVES_TALLY_FORM_ID: 'w5xDGE',
  COMPLAIN_SUGGEST_TALLY_FORM_ID: 'woGjJe',
};