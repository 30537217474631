<div class="sticky-header-wrapper">
  <button class="hamburger-menu-button" (click)="onOpenMobileNavBar()">
    <img
      loading="lazy"
      class="hamburger-menu-button__icon"
      src="assets/img/header-icons/mobile-header-icons/hamburger-menu.svg"
      alt=""
    />
  </button>
  <a class="taager-logo" [routerLink]="logoRouteLink">
    <img
      loading="lazy"
      src="assets/img/header-icons/logo-dark.svg"
      title="Taager Logo"
      alt="The logo of Taager website"
      class="taager-logo__image"
      *ngIf="darkMode$ | async; else logoLight"
    />
    <ng-template #logoLight>
      <img
        loading="lazy"
        src="assets/img/header-icons/taager-logo-2022.svg"
        title="Taager Logo"
        alt="The logo of Taager website"
        class="taager-logo__image"
      />
    </ng-template>
  </a>
  <app-products-search-bar></app-products-search-bar>
  <app-stores-navigation *ngIf="isStoresEnabled"></app-stores-navigation>
  <app-shopping-navigation></app-shopping-navigation>
  <app-account-dropdown [userName]="user.fullName"></app-account-dropdown>
</div>
