<div class="layout-header">
  <div class="taager-logo">
    <a routerLink="/">
      <img
        loading="lazy"
        src="assets/img/header-icons/taager-logo-2022.svg"
        title="Taager Logo"
        alt="The logo of Taager website"
        class="taager-logo__image"
      />
    </a>
  </div>
  <button class="close-btn" *ngIf="showCloseButton" (click)="onClose()">
    <i class="icon icon-header-icons-close close-btn-icon"></i>
    <p class="close-btn-text body2--medium">إغلاق</p>
  </button>
</div>
<router-outlet></router-outlet>
