<div class="wrapper">
  <div class="wrapper__section-one">
    <div class="wrapper__section-one__salutation heading2--extra-bold">
      <img
        class="country-flag"
        src="{{ currentCountry.flag }}"
        [alt]="currentCountry.name + 'icon'"
      />
      {{ 'NET_DELIVERY_RATE.TEXT_1' | translate : { name: fullName } }}
    </div>
    <div class="wrapper__section-one__instructions header body2--medium">
      {{
        'NET_DELIVERY_RATE.TEXT_2' | translate : { duration: netDeliveryRateResolver.activePeriod }
      }}
    </div>
  </div>
  <div class="wrapper__section-two" (click)="impliedIntentionToExploreNDR()">
    <div
      class="wrapper__section-two__percent heading2--extra-bold {{
        netDeliveryRateResolver.ndrLevel
      }}"
    >
      {{ netDeliveryRateResolver.ndrValue + '%' }}
    </div>
    <div class="wrapper__section-two__indicators">
      <div
        [ngClass]="{
          'below-average':
            idx <= netDeliveryRateResolver.ndrLevelGroups['below-average'].end! ||
            netDeliveryRateResolver.ndrBatteryLevel <=
              netDeliveryRateResolver.ndrLevelGroups['below-average'].end!,
          average:
            (idx >= netDeliveryRateResolver.ndrLevelGroups['average'].start! &&
              idx <= netDeliveryRateResolver.ndrLevelGroups['average'].end!) ||
            (netDeliveryRateResolver.ndrBatteryLevel! >=
              netDeliveryRateResolver.ndrLevelGroups['average'].start! &&
              netDeliveryRateResolver.ndrBatteryLevel! <=
                netDeliveryRateResolver.ndrLevelGroups['average'].end!),
          'above-average':
            idx >= netDeliveryRateResolver.ndrLevelGroups['above-average'].start! ||
            netDeliveryRateResolver.ndrBatteryLevel! >=
              netDeliveryRateResolver.ndrLevelGroups['above-average'].start!,
          'is-visible': idx + 1 <= netDeliveryRateResolver.ndrBatteryLevel
        }"
        class="wrapper__section-two__indicators__level"
        *ngFor="let level of levels; let idx = index"
      ></div>
    </div>
  </div>
</div>
