/* eslint-disable max-len */
import { environment } from 'src/environments/environment';
import * as AUTH from './auth';
import * as COMPLAINTS from './complaints';
import * as FLAGS from './feature-flags';
import * as STATUSES from './order-statuses';
import * as PAYMENT_REQUESTS from './payment-requests';

/* eslint-disable @typescript-eslint/naming-convention */
export const API_URLS = {
  /* Cart Service URLs */
  ADD_TO_CART_URL: ({
    productId,
    sellerName = 'sellerName',
    quantity,
    overwriteQuantity,
  }: {
    productId: string;
    sellerName?: string;
    quantity: number;
    overwriteQuantity: boolean;
  }): string =>
    `${environment.BACKEND_URL}api/user/addToCart/${productId}/${sellerName}/${quantity}/${overwriteQuantity}`,
  REMOVE_FROM_CART_URL: (productId: string): string =>
    `${environment.BACKEND_URL}api/user/removeFromCart/${productId}/unimportant`,
  GET_CART_DATA_URL: `${environment.BACKEND_URL}api/user/getCart`,
  ADD_TO_CART_WITH_CUSTOM_PRICE_URL: `${environment.BACKEND_URL}api/user/addToCart`,
  GET_USER_CART: `${environment.BACKEND_URL}api/v2/users/cart`,
  UPDATE_CART: `${environment.BACKEND_URL}api/users/cart`,

  /* Stock Availability URLs */
  GET_STOCK_AVAILABILITY_URL: (productId: string): string =>
    `${environment.BACKEND_URL}api/products/${productId}/stock-availability`,
  SEND_NOTIFY_ME_REQUESTS_URL: (productId: string): string =>
    `${environment.BACKEND_URL}api/products/${productId}/notify-me`,

  /* Products Service URLs */
  // GET_PRODUCTS_FOR_CATEGORY_URL: `${environment.BACKEND_URL}api/product/searchQuery`,
  GET_PRODUCTS_FOR_CATEGORY_URL: `${environment.BACKEND_URL}api/variant-groups/search`,
  GET_PRODUCT_BY_ID_URL: (id: string): string =>
    `${environment.BACKEND_URL}api/product/viewProduct/${id}`,
  GET_VARIANT_GROUP_BY_VARIANT_ID: (id: string): string =>
    `${environment.BACKEND_URL}api/variantGroup/variant/${id}`,
  GET_PRODUCTS_BY_ID_URL: `${environment.BACKEND_URL}api/product/getProductsByIds`,
  GET_PRODUCTS_BY_PROD_IDS_URL: `${environment.BACKEND_URL}api/product/getProductsByProdIds`,
  GET_UPSELLABLE_VARIANTS_BY_PROD_ID_URL: (prodId: string): string =>
    `${environment.BACKEND_URL}api/products/${prodId}/up-sellable`,
  GET_BEST_SELLERS_URL: `${environment.BACKEND_URL}api/product/getBestsellersList`,
  FEATURED_PRODUCTS_GROUP_URL: (id: number, country: string): string =>
    `${environment.BACKEND_URL}api/featuredProductsGroup/${id}?country=${country}`,
  REQUEST_PRODUCT_URL: `${environment.BACKEND_URL}api/product/request`,
  PRODUCT_REVEIW_URL: (productId: string): string =>
    `${environment.BACKEND_URL}api/products/${productId}/review`,
  PRODUCT_CHECKOUT_IMAGES_URL: `${environment.BACKEND_URL}api/products/images`,
  /* Catalog URLs */
  CATALOG_PRODUCT_BY_ID_URL: (id: string): string =>
    `${environment.BACKEND_URL}api/product/favourite/${id}/set`,
  UNCATALOG_PRODUCT_BY_ID_URL: (id: string): string =>
    `${environment.BACKEND_URL}api/product/favourite/${id}/unset`,
  GET_PRODUCT_IS_CATALOGED: (id: string): string =>
    `${environment.BACKEND_URL}api/product/favourite/${id}`,
  GET_CATALOGED_PRODUCTS: (isLocked: boolean): string =>
    `${environment.BACKEND_URL}api/product/favourite?isLocked=${isLocked}`,
  UPDATE_CUSTOM_PRICE: (id: string): string =>
    `${environment.BACKEND_URL}api/product/favourite/${id}`,

  /* Complains and Suggestions Service URLs */
  CREATE_COMPLAIN_OR_SUGGESTION_URL: `${environment.BACKEND_URL}api/complains-suggestions`,

  /* Tableau Workbook url */
  MERCHANT_PERORMANCE_OVERWIEW_URL:
    'https://dub01.online.tableau.com/t/taager/views/MerchantAnalytics/Overview?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',

  /* Order Issues Service URLs */
  ADD_ORDER_REFUND_URL: `${environment.BACKEND_URL}api/orderIssues/addOrderRefund`,
  ADD_ORDER_REPLACEMENT_URL: `${environment.BACKEND_URL}api/orderIssues/addOrderReplacement`,
  ADD_ORDER_COMPLETION_URL: `${environment.BACKEND_URL}api/orderIssues/addOrderCompletion`,
  ADD_ISSUE_ATTACHMENT_URL: `${environment.BACKEND_URL}api/orderIssues/addIssueAttachment`,

  /* Payment Request Service URLs */
  GET_WITHDRAWLS_LIST_REQUESTS_URL: `${environment.BACKEND_URL}api/withdrawals/list`,
  CREATE_WITHDRAWAL_REQUEST_URL: `${environment.BACKEND_URL}api/withdrawals/request`,
  WITHDRAWAL_REQUEST_OTP_REQUEST_URL: `${environment.BACKEND_URL}api/withdrawals/request-otp`,
  WITHDRAW_INTL_BANK_TRANSFER: `${environment.BACKEND_URL}api/withdrawals/request-international-bank-transfer`,
  WITHDRAW_EGP_BANK_TRANSFER: `${environment.BACKEND_URL}api/withdrawals/request-egyptian-bank-transfer`,
  WITHDRAW_PAYONEER_TRANSFER: `${environment.BACKEND_URL}api/withdrawals/request-payoneer`,

  /* Profile Service URLs */
  GET_PROFILE_URL: `${environment.BACKEND_URL}api/user/viewOwnProfile`,
  UPDATE_PROFILE_DEVICE_TOKENS_URL: `${environment.BACKEND_URL}api/user/updateProfileDeviceTokens`,
  GET_WALLETS_URL: `${environment.BACKEND_URL}api/wallet`,
  GET_USER_ORDERS_URL: `${environment.BACKEND_URL}api/order/viewMyOrders`,
  GET_USER_ORDERS_EXTRACT_URL: `${environment.BACKEND_URL}api/order/viewMyOrdersExtract`,
  SEARCH_IN_USER_ORDERS_URL: `${environment.BACKEND_URL}api/order/searchInMyOrders`,
  SEARCH_IN_USER_CHILD_ORDERS_URL: ({
    pageNum,
    pageSize,
    searchKey,
  }: {
    pageNum: number;
    pageSize: number;
    searchKey: string;
  }): string =>
    `${environment.BACKEND_URL}api/child-order/search?page=${pageNum}&pageSize=${pageSize}&filter=${searchKey}`,
  GET_MERCHANT_INCENTIVE_PROGRAM: `${environment.BACKEND_URL}api/merchant/incentive-program`,
  GET_MERCHANT_NEW_INCENTIVE_PROGRAM: `${environment.BACKEND_URL}api/engagement/incentive-program`,
  GET_MERCHANT_SHOPPING_SUMMARY: `${environment.BACKEND_URL}api/merchant/shopping-summary`,
  GET_OPERATION_RATES: `${environment.BACKEND_URL}api/order/operations-rates`,

  /* Shipping Service URLs */
  GET_AWB_FROM_BOSTA_URL: `${environment.BACKEND_URL}api/shipping/getAWBFromBosta`,
  TRACK_PACKAGE_FROM_BOSTA_URL: `${environment.BACKEND_URL}api/shipping/trackBostaOrder`,
  TRACK_PACKAGE_FROM_DREEVO_URL: `${environment.BACKEND_URL}api/shipping/trackPackageFromDreevo`,
  TRACK_SHIPMENT_FROM_ARAMEX_URL: `${environment.BACKEND_URL}api/shipping/aramex-track`,
  TRACK_SHIPMENT_FROM_VHUBS_URL: `${environment.BACKEND_URL}api/shipping/vhubs-track`,

  /* User Service URLs */
  GENERATE_REFERRAL_CODE_URL: `${environment.BACKEND_URL}api/user/generateReferralCode`,
  GET_ANNOUNCEMENT_URL: `${environment.BACKEND_URL}api/announcement/getAnnouncements`,
  SET_FRESH_CHAT_RESTORE_ID_URL: `${environment.BACKEND_URL}api/user/setFreshChatRestoreId`,
  UPDATE_USER_PROFILE_URL: `${environment.BACKEND_URL}api/user/updateProfile`,

  /* Auth Service URLs */
  WALLET_LOGIN_URL: `${environment.BACKEND_URL}api/auth/wallet-login`,
  SOCIAL_AUTH_SIGN_IN_URL: `${environment.BACKEND_URL}api/auth/socialAuthSignIn`,
  FORGOT_PASSWORD_URL: `${environment.BACKEND_URL}api/auth/forgotPassword`,
  CHANGE_PASSWORD_URL: `${environment.BACKEND_URL}api/auth/changePassword`,
  CHANGE_WALLET_PASSWORD_URL: `${environment.BACKEND_URL}api/auth/changeWalletPassword`,
  RESET_PASSWORD_URL: (id: string, resetToken: string): string =>
    `${environment.BACKEND_URL}api/auth/resetPassword/${id}/${resetToken}`,
  FORGOT_WALLET_PASSWORD_URL: `${environment.BACKEND_URL}api/auth/forgotWalletPassword`,
  RESET_WALLET_PASSWORD_URL: (id: string, resetToken: string): string =>
    `${environment.BACKEND_URL}api/auth/resetWalletPassword/${id}/${resetToken}`,
  UPDATE_PICTURE_URL: `${environment.BACKEND_URL}api/auth/updatePic`,

  /* Auth Service URLs */
  LOGIN_URL: `${environment.BACKEND_URL}api/users/login`,
  REGISTER_URL: `${environment.BACKEND_URL}api/users/register`,
  REQUEST_OTP_URL: `${environment.BACKEND_URL}api/users/phone-number`,
  REQUEST_EMAIL_OTP_URL: `${environment.BACKEND_URL}api/users/email/verification/otp`,
  VERIFY_PHONE_URL: `${environment.BACKEND_URL}api/users/phone-number/verify`,
  VERIFY_EMAIL_URL: `${environment.BACKEND_URL}api/users/email/verification/otp/verify`,
  UPDATE_USER_PROFILE_V2_URL: `${environment.BACKEND_URL}api/users/user-profile`,

  /* Questionnnaire for Banners */
  GET_BANNER_QUESTIONNAIRE_ELIGIBILITY_URL: (questionnaireName: string): string =>
    `${environment.BACKEND_URL}api/questionnaires/${questionnaireName}/is-enabled`,
  POST_BANNER_QUESTIONNAIRE_DECLINE_URL: (questionnaireName: string): string =>
    `${environment.BACKEND_URL}api/questionnaires/${questionnaireName}/decline`,
  GET_QUESTIONNAIRE_QUESTIONS: (questionnaireName: string): string =>
    `${environment.BACKEND_URL}api/questionnaires/${questionnaireName}`,
  SUBMIT_QUESTIONNAIRE_QUESTIONS: (questionnaireName: string): string =>
    `${environment.BACKEND_URL}api/questionnaires/${questionnaireName}/answers`,

  /* Multitenancy */
  GET_COUNTRIES_URL: `${environment.BACKEND_URL}api/countries`,
  UPDATE_SWITCHED_SELECTED_MARKET: `${environment.BACKEND_URL}api/users/selected-market`,

  /* Bulk pre-orders */
  GET_BULK_PREORDERABLE_PRODUCTS_URL: `${environment.BACKEND_URL}api/preorder/products`,
  CREATE_BULK_PRE_ORDER_REQUEST_URL: `${environment.BACKEND_URL}api/preorder/request`,
  PRE_ORDER_V2_URL: `${environment.BACKEND_URL}api/v2/preorders/requests`,
  GET_BULK_PRE_ORDER_REQUEST_URL: `${environment.BACKEND_URL}api/preorder/requests`,
  ACCEPT_PRE_ORDER_DOWN_PAYMENT_URL: (requestId: string): string =>
    `${environment.BACKEND_URL}api/preorder/requests/${encodeURIComponent(
      requestId,
    )}/accept-down-payment`,
  REJECT_PRE_ORDER_DOWN_PAYMENT_URL: (requestId: string): string =>
    `${environment.BACKEND_URL}api/preorder/requests/${encodeURIComponent(
      requestId,
    )}/reject-down-payment`,
  GET_PRE_ORDER_CATEGORIES_URL: `${environment.BACKEND_URL}api/preorder/categories`,

  /* Wallet */
  GET_WALLET_TRANSACTION_HISTORY_URL: `${environment.BACKEND_URL}api/wallet/transaction-history`,

  /* Lead */
  GET_LEAD_LIST: `${environment.BACKEND_URL}api/lead-management/leads`,

  /* Province */
  PROVINCE_LIST_URL_ALL: (countryCode: string) =>
    `${environment.BACKEND_URL}admin/countries/${countryCode}/provinces-with-zones-and-districts`,
  PROVINCE_ZONES_LIST_URL: (filter: { country: string }, province: string) =>
    `${environment.BACKEND_URL}admin/countries/${filter.country}/provinces/${province}/zones`,
  PROVINCE_ZONE_DISTRICTS_LIST_URL: (filter: { country: string }, province: string, zone: string) =>
    `${environment.BACKEND_URL}admin/countries/${filter.country}/provinces/${province}/zones/${zone}/districts`,
};

export const INCENTIVE_IMG_URL = 'assets/img/incentive-program/';

export const ORDER_STATUSES = STATUSES;
export const COMPLAINT = COMPLAINTS;
export const FEATURE_FLAGS = FLAGS;
export const PAYMENT_REQUESTS_CONSTS = PAYMENT_REQUESTS;
export const AUTH_CONSTS = AUTH;
/**
 * Well, to make things a bit cleaner and organized, please avoid adding constants to this
 * file, rather export them from their logical grouping, and only export the contents from
 * their logical grouping here.
 */
export * from './app-urls';
export * from './landing';
export * as LANDING_CONSTS from './landing';
export * from './local-storage.constants';
export * from './terms-and-conditions';
export * from './user-features.constants';

export const dateFormat = 'dd/MM/yyyy';
export const snakeCaseDateFormat = 'dd-MM-yyyy';
export const fullYearMonthDateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'dd/MM/yyyy, hh:mm a';
export const incentiveDateFormat = 'D-M-YYYY';
export const monthNameDateFormat = 'dd MMM, yyyy';
export const monthNameDayYearFormat = 'MMMM d, yyyy';

export const LOCAL_STORAGE_PREFIX = 'Taager-LocalStore.key.3';
export const USER = 'user';
export const USER_TOKEN = 'user-token';
export const REFRESH_TOKEN = 'refresh-token';
export const WALLET = 'wallet';
export const LOCAL_STORAGE_QUERY_PARAMS_KEY = 'queryParams';
export const FCM_NOTIFICATION_TOKEN = 'fcmNotificationToken';
export const REDIRECT_URL = 'redirectUrl';
export const SELECTED_COUNTRY_ISO_CODE_2 = 'selectedCountry';
export const DISPLAY_NEW_PRODUCTS_PAGE = 'displayNewProductsPage;';

export const WALLET_SURVEY_NAME = 'wallet-transactions';

export const WALLET_FEATURE = 'protected_wallet';
export const STATICS_FEATURE = 'view_statistics';
export const INCENTIVE_PROGRAM_FEATURE = 'incentive_program';
export const CREATIVE_REQUEST_FEATURE = 'creative_request_enabled_';
export const MULTITENANCY_FEATURE = 'multitenancy';
export const MULTITENANCY_UAE_FEATURE = 'multitenancy_uae';

export const ALL_PRODUCTS_CATEGORY = 'PRODUCTS_PAGE.CAROUSEL_NAMES.ALL_PRODUCTS_CAROUSEL_NAME';
export const ALL_SALE_PRODUCTS_CAROUSEL = 'PRODUCTS_PAGE.CAROUSEL_NAMES.FLASH_SALES_CAROUSEL_NAME';
export const DISCOUNTED_PRODUCTS_CAROUSEL_TITLE =
  'PRODUCTS_PAGE.CAROUSEL_NAMES.SECOND_IN_PLACEMENT_CAROUSEL_NAME';
export const TAAGER_EXCLUSIVE_OFFFERS_CATEGORY =
  'PRODUCTS_PAGE.CAROUSEL_NAMES.TAAGER_EXCLUSIVE_OFFFERS_CATEGORY';

export const TOYS_CATEGORY = 'العاب';
export const SELF_CARE_CATEGORY = 'عناية شخصية';
export const LOCKED_PRODUCTS_CATEGORY = 'منتجات مقفولة عليك فقط لبيعها!';
export const UPSELLABLE_PRODUCTS_CATEGORY = 'يباع جيداً مع';

export const INTRODUCED_AT_CAROUSEL_NAME =
  'PRODUCTS_PAGE.CAROUSEL_NAMES.INTRODUCED_AT_CAROUSEL_NAME';
export const LOCKED_PRODUCTS_CAROUSEL_NAME =
  'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME';
export const COLLECTION_PRODUCTS_CAROUSEL_NAME =
  'PRODUCTS_PAGE.CAROUSEL_NAMES.COLLECTION_PRODUCTS_CAROUSEL_NAME';
export const BULK_PREORDERABLE_CAROUSEL_NAME =
  'PRODUCTS_PAGE.CAROUSEL_NAMES.BULK_PRE_ORDER_CAROUSEL_NAME';
export const BULK_PREORDERABLE_CAROUSEL_NAME_ARABIC = 'خدمة المنتجات الحصرية';
export const BULK_PREORDERABLE_CAROUSEL_NAME_ENGLISH = 'Exclusive Products Service';

export const PAGES_FILTER_SIZE = [12, 24, 48, 96];

export const UP_SELLABLE_FEEDBACK_NAME = 'upselling-survey';

export const STOCK_AVAILABILITY_USER_FEATURE = 'stock_availability_';
export const YOUCAN_USER_FEATURE = 'youcan_';
export const MERCHANT_INSIGHTS_USER_FEATURE = 'merchant_insights';
export const DISCOVERABILITY_FUNNEL_USER_FEATURE = 'discoverability_funnel';
export const PRE_ORDER_USER_FEATURE = 'preorder_';

export const ADDRESS_AUTOCONFIRMATION_USER_FEATURE = 'auto-confirmation-with-address';

export const STOCK_AVAILABILITY_BUNDLE_NOT_FOUND = 'not-found-for-bundle';
export const STOCK_AVAILABILITY_BUNDLE_NOT_FOUND_MSG = 'توافر حزمة المنتجات سيتوافر قريباً';
export const STOCK_AVAILABILITY_INFO_NOT_FOUND_MSG = 'لم يتم العثور على معلومات لهذا المنتج';

export const SKU_PERFORMANCE_USER_FEATURE = 'sku_analytics_';
export const CPA_CALCULATOR_USER_FEATURE = 'cpa_calculator';
export const FAILED_ORDERS_USER_FEATURE = 'failed_orders';

export const KYC_USER_FEATURE = 'kyc';

export const EGY_CURRENCY_ARABIC_NAME = 'جنيه مصري';
export const KSA_CURRENCY_ARABIC_NAME = 'ريال سعودي';
export const UAE_CURRENCY_ARABIC_NAME = 'درهم اماراتي';
export const EGY_CURRENCY_SHORT_ARABIC_NAME = 'ج.م';
export const KSA_CURRENCY_SHORT_ARABIC_NAME = 'ر.س';
export const UAE_CURRENCY_SHORT_ARABIC_NAME = 'د.ا';

export const DYNAMIC_INCENTIVE_SATISFIED = 'SATISFIED';
export const DYNAMIC_INCENTIVE_VERY_SATISFIED = 'VERY_SATISFIED';
export const DYNAMIC_INCENTIVE_NOT_SATISFIED = 'NOT_SATISFIED';

export const DUKAN_PRODUCTS_LABEL = 'products';
export const DUKAN_SETTINGS_LABEL = 'settings';
export const DUKAN_INTEGRATIONS_LABEL = 'integrations';

export const CURRENCY_LIST = [
  {
    currency: EGY_CURRENCY_ARABIC_NAME,
    shortName: EGY_CURRENCY_SHORT_ARABIC_NAME,
  },
  {
    currency: KSA_CURRENCY_ARABIC_NAME,
    shortName: KSA_CURRENCY_SHORT_ARABIC_NAME,
  },
  {
    currency: UAE_CURRENCY_ARABIC_NAME,
    shortName: UAE_CURRENCY_SHORT_ARABIC_NAME,
  },
];

export type OVERLAY_TYPES =
  | 'pleaseVerify'
  | 'success'
  | 'noOverlay'
  | 'optInCountryNotCovered'
  | 'onboardingGettingStarted'
  | 'lastOnboardingStep';

export type NEXT_STEP_FOR_THE_USER = 'details' | 'otp' | 'reLogin' | 'products';

export type USER_TYPES = 'legacy' | 'new';

export const CURRENCIES = {
  EGP: 'EGP',
  SAR: 'SAR',
  AED: 'AED',
  USD: 'USD',
};

export enum GEO_TRACKING_FUNCTION_NAMES {
  getCountryName = 'getCountryName',
  getCityName = 'getCityName',
}

export type DEVICE_TYPES = 'mobile' | 'tablet' | 'desktop';

export type OrdersTab = { key: string; name: string; hasNumberOfOrders?: boolean };

export const ZID_PROVIDER_NAME = 'zid';

export const YOUCAN_PROVIDER_NAME = 'youcan';

export const LOCAL_STORAGE_ONBOARDING = 'onboarding';
export const HIDDEN_IFRAME_CLASS_NAME = 'hide-iframe';

export const EMAIL_REGULAR_EXPRESSION =
  '[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}' +
  '\\@' +
  '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}' +
  '(' +
  '\\.' +
  '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}' +
  ')+';

export const PRE_ORDERABLE_PRODUCTS = 'preorderablProducts';

export const LOYALTY_PROGRAM_FEATURE = 'loyalty_program';

export const REFERRAL_PROGRAM_FEATURE = 'referral_program';

export const DYNAMIC_INCENTIVE_FEATURE = 'dynamic_incentive_program';
