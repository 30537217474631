import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { hazelnut } from '@mentoor.io/hazelnut-client';
import cache from '@mongez/cache';
import { GenericObject } from '@mongez/reinforcements';
import { parseError } from '@presentation/shared/error';
import { currentRoute } from '@presentation/shared/router';
import packageInfo from 'package.json';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutUseCase } from 'src/app/core/usecases/auth/logout.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { PRODUCTS_ENDPOINTS } from 'src/app/data/repositories/products/products-apis-endpoints';
import { API_URLS, LOGIN_URL, REDIRECT_URL } from '../constants';

/** Inject With Credentials into the request */
@Injectable({
  providedIn: 'root',
})
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private _logoutUseCase: LogoutUseCase,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const { accessToken } = user;
    if (accessToken) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
      });
      if (this._shouldSetCountryHeader(req)) {
        req = req.clone({
          headers: req.headers.set('country', country.code),
        });
      }
      if (this._shouldSetUserHeader(req)) {
        req = req.clone({
          headers: req.headers.set('taagerId', `${user.id}`),
        });
      }
    }
    req = req.clone({
      headers: req.headers
        .set('version', `${packageInfo.version}`)
        .set('platform', 'web')
        .set('ui-session-key', user.uiSessionKey),
    });
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const mapDataToObject = (data: HttpHeaders | HttpParams): GenericObject => {
          const output: GenericObject = {};

          data.keys().forEach((key) => {
            output[key] = data.get(key);
          });

          return output;
        };

        const payload: GenericObject = {
          request: {
            url: req.url,
            method: req.method,
            params: mapDataToObject(req.params),
          },
        };

        if (err instanceof HttpErrorResponse) {
          payload.response = {
            data: err.error,
            status: err.status,
            statusText: err.statusText,
            headers: mapDataToObject(err.headers),
          };

          if (err.status === 401) {
            this._handle401();
          }
        }

        hazelnut.error(new Error(parseError(err)), payload);

        return throwError(() => err);
      }),
    );
  }

  private _handle401(): void {
    const path = currentRoute();

    cache.set(REDIRECT_URL, path);

    if (!path.includes(LOGIN_URL)) {
      this._logoutUser();
    }
  }

  private _logoutUser(): void {
    this._logoutUseCase.execute();
  }

  private _shouldSetCountryHeader(request: HttpRequest<any>): boolean {
    const excludedApiUrls = [
      API_URLS.GET_PRODUCTS_BY_PROD_IDS_URL,
      API_URLS.GET_PRODUCTS_BY_ID_URL,
    ];
    return !excludedApiUrls.includes(request.url);
  }

  private _shouldSetUserHeader(request: HttpRequest<any>): boolean {
    const includedAPIUrls = [PRODUCTS_ENDPOINTS.CREATE_PRODUCT_CHECKOUT];
    return includedAPIUrls.includes(request.url);
  }
}
