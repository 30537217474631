/* eslint-disable import/no-cycle */
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { country } from '@features/country/data';
import { DateWrapperUtility } from '../shared/utilities/date-wrapper.utility';
import { NetDeliveryRateComponent } from './components/net-delivery-rate.component';

@Injectable({ providedIn: 'root' })
export class NetDeliveryRateResolver {
  private _showNDR: boolean;

  public ndrLevel: 'below-average' | 'average' | 'above-average';

  public ndrLevelGroups: { [group: string]: { start?: number; end?: number } };

  public ndrValue: number;

  public ndrBatteryLevel: number;

  public maxBatteryLevels: number;

  private _componentRef: ComponentRef<NetDeliveryRateComponent>;

  private _prevOutlet: ViewContainerRef;

  public activePeriod: string;

  public ndrStartDate: string;

  constructor() {
    this._showNDR = true;
    this.maxBatteryLevels = 14;
    this.ndrLevelGroups = {
      'below-average': { end: 3 },
      average: { start: 3, end: 6 },
      'above-average': { start: 7 },
    };
    this.ndrStartDate = 'December 1, 2022';
    const { month } = DateWrapperUtility.getFormattedDateParts(
      this.ndrStartDate,
      { month: 'MMMM' },
      'ar',
    );
    const { year, day } = DateWrapperUtility.getFormattedDateParts(
      this.ndrStartDate,
      { day: 'D', year: 'YYYY' },
      'en',
    );
    this.activePeriod = `${day} ${month} ${year}`;
  }

  public analyzeNDR(outlet: ViewContainerRef, ndr: number): void {
    this.ndrValue = Math.round(ndr);
    this._analyzeNDR();
    this._calculateNDRLevel();
    this._optionallyInjectUI(outlet);
  }

  private _analyzeNDR(): void {
    if (country.is('EGY')) this._analyzeEgyptNDR();
    if (country.is('KSA')) this._analyzeSaudiNDR();
  }

  private _calculateNDRLevel(): void {
    const ndrValue = Math.round(this.ndrValue);
    this.ndrBatteryLevel = Math.floor((ndrValue / 100) * this.maxBatteryLevels);
    if (ndrValue <= 21) {
      this.ndrLevel = 'below-average';
    } else if (ndrValue < 50) {
      this.ndrLevel = 'average';
    } else {
      this.ndrLevel = 'above-average';
    }
  }

  private _optionallyInjectUI(outlet: ViewContainerRef): void {
    this._clearNDRUI();
    if (this._showNDR) {
      this._injectNDRDisplay(outlet);
    }
  }

  /**
   * We are leaving this here, because later we may have other calculations
   */
  private _analyzeEgyptNDR(): void {
    this._showNDR = true;
  }

  /**
   * We are leaving this here, because later we may have other calculations
   */
  private _analyzeSaudiNDR(): void {
    this._showNDR = true;
  }

  private _injectNDRDisplay(outlet: ViewContainerRef): void {
    this._prevOutlet = outlet;
    this._componentRef = this._prevOutlet.createComponent(NetDeliveryRateComponent);
  }

  private _clearNDRUI(): void {
    if (this._prevOutlet) {
      this._prevOutlet.clear();
    }
    if (this._componentRef) {
      this._componentRef.destroy();
    }
  }
}
