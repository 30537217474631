enum FailedReasons {
  INVALID_PROVINCE = 'invalid_province',
  INVALID_COUNTRY = 'invalid_country',
  INVALID_ADDRESS = 'invalid_address',
  PRICE_ERROR = 'price_error',
  TOO_HIGH_QUANTITY = 'too_high_quantity',
  MARKET_CLOSED = 'market_closed',
  PRODUCT_NOT_FOUND = 'product_not_found',
  PRODUCT_NOT_AVAILABLE = 'product_not_available',
  PRODUCT_STOCK_NOT_AVAILABLE = 'product_stock_not_available',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  SPAMMER_END_CUSTOMER = 'spammer_end_customer',
  SPAMMER_MERCHANT = 'spammer_merchant',
}

export const FAILED_ORDERS_REASON_MAP = new Map<string, string>([
  [FailedReasons.INVALID_PROVINCE, 'FAILED_ORDERS.REASONS.INVALID_PROVINCE'],
  [FailedReasons.INVALID_COUNTRY, 'FAILED_ORDERS.REASONS.INVALID_COUNTRY'],
  [FailedReasons.INVALID_ADDRESS, 'FAILED_ORDERS.REASONS.INVALID_ADDRESS'],
  [FailedReasons.PRICE_ERROR, 'FAILED_ORDERS.REASONS.PRICE_ERROR'],
  [FailedReasons.TOO_HIGH_QUANTITY, 'FAILED_ORDERS.REASONS.TOO_HIGH_QUANTITY'],
  [FailedReasons.MARKET_CLOSED, 'FAILED_ORDERS.REASONS.MARKET_CLOSED'],
  [FailedReasons.PRODUCT_NOT_FOUND, 'FAILED_ORDERS.REASONS.PRODUCT_NOT_FOUND'],
  [FailedReasons.PRODUCT_NOT_AVAILABLE, 'FAILED_ORDERS.REASONS.PRODUCT_NOT_AVAILABLE'],
  [FailedReasons.PRODUCT_STOCK_NOT_AVAILABLE, 'FAILED_ORDERS.REASONS.PRODUCT_STOCK_NOT_AVAILABLE'],
  [FailedReasons.INVALID_PHONE_NUMBER, 'FAILED_ORDERS.REASONS.INVALID_PHONE_NUMBER'],
  [FailedReasons.SPAMMER_END_CUSTOMER, 'FAILED_ORDERS.REASONS.SPAMMER_END_CUSTOMER'],
  [FailedReasons.SPAMMER_MERCHANT, 'FAILED_ORDERS.REASONS.SPAMMER_MERCHANT'],
]);
