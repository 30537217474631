/* eslint-disable no-empty */
import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import * as mixpanel from 'mixpanel-browser';
import packageInfo from 'package.json';
import { combineLatest } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetRemoteConfigUsecase } from 'src/app/core/usecases/get-remote-config.usecase';
import { environment } from 'src/environments/environment';
import { INCENTIVE_PROGRAM_FEATURE } from '../constants';
import {
  SEARCH_OPTIMIZATION_FEATURE,
  STOCK_AVAILABILITY_FEATURE,
} from '../constants/feature-flags';
import { translateObjectValues } from '../utilities/translate-object-values.utility';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor(
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _getRemoteConfigUsecase: GetRemoteConfigUsecase,
  ) {}

  /**
   * Initialize mixpanel.
   *
   * @param userToken
   * @memberof MixpanelService
   */
  init(): void {
    if (environment.MIXPANEL_PROXY_DOMAIN) {
      mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN, {
        api_host: environment.MIXPANEL_PROXY_DOMAIN,
      });
    } else {
      mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN);
    }
  }

  /**
   * Identify a user with a unique ID, currently his Taager ID
   */
  identify(): void {
    if (!this.mixpanelCanBeUsedOnUserDevice()) {
      return;
    }

    const tagerID: string = user?.id?.toString();

    mixpanel.identify(tagerID);
    this.setUpUser(tagerID, user.data);
    this.setUpSuperProperties(user);
  }

  /**
   * Sets user properties
   * Overrides existing properties if changed
   * Should be called after login and sign up
   *
   * @param user Includes user properties to track
   *
   */
  private setUpUser(tagerID: string, userData: any = {}): void {
    const userObject = {
      $tager_ID: tagerID,
      $phone: userData?.phoneNum,
      $email: userData?.email,
      $name: userData?.fullName
        ? userData?.fullName
        : `${userData?.firstName} ${userData?.lastName}`,
      join_date: userData?.createdAt,
    };

    mixpanel.people.set(userObject);
  }

  /**
   * Register a set of super properties, which will be included with all events.
   * This will overwrite previous super property values.
   */
  private setUpSuperProperties(userData: any = {}) {
    let superProperties: any = {
      $email: userData?.email,
      $version: packageInfo.version,
      $is_incentive_program_enabled: !!userData?.features?.includes(INCENTIVE_PROGRAM_FEATURE),
      $ui_session_key: user.uiSessionKey,
    };
    const selectedCountry = country.code;
    superProperties = {
      ...superProperties,
      $selected_country: selectedCountry,
    };
    mixpanel.register(superProperties);
    const stockAvailabilityFeature$ = this._getFeatureFlagUsecase.execute(
      STOCK_AVAILABILITY_FEATURE,
    );
    const searchOptimizationFeature$ = this._getFeatureFlagUsecase.execute(
      SEARCH_OPTIMIZATION_FEATURE,
    );
    combineLatest({
      stockAvailabilityFeature: stockAvailabilityFeature$,
      searchOptimizationFeature: searchOptimizationFeature$,
    }).subscribe({
      next: ({ stockAvailabilityFeature, searchOptimizationFeature }) => {
        // the register here will append the values below to the older register
        // since the subsription might be async
        mixpanel.register({
          ...superProperties,
          $is_stock_availability_enabled: stockAvailabilityFeature,
          $is_search_optimization_enabled: searchOptimizationFeature,
        });
      },
    });
    this._appendRemoteConfig();
  }

  private _appendRemoteConfig() {
    this._getRemoteConfigUsecase.execute().subscribe({
      next: (remoteConfigs) => {
        mixpanel.register({ ...remoteConfigs });
      },
    });
  }

  /**
   * Push new action to mixpanel.
   *
   * @param id Name of the action to track.
   * @param [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if (!this.mixpanelCanBeUsedOnUserDevice()) {
      return;
    }

    if (user.isLoggedIn) {
      mixpanel.track(id, translateObjectValues({ objectToTranslate: action }));
    }
  }

  /**
   *
   * @returns
   *
   * So, at times we need to clean out the mixpanel slate and have a new one,
   * this is where we have this method.
   *
   * Why? Well, please lets take a look at the documentation here. We will see
   * how mixpanel persists a user story.
   *
   * https://help.mixpanel.com/hc/en-us/articles/360041039771-Getting-Started-with-Identity-Management
   *
   * Specifically point #3 in Identify Returning User
   */
  reset(): void {
    if (!this.mixpanelCanBeUsedOnUserDevice()) {
      /**
       * Okay, you may wonder why we are calling this check, when reseting, doesn't it mean that the
       * instance had already been initialized? Well, good question. However, we don't know what the
       * user may have changed on their machine that would possibly interfere with how mixpanel works.
       */
      return;
    }
    mixpanel.reset();
  }

  private mixpanelCanBeUsedOnUserDevice() {
    let verdict = false;
    try {
      /**
       * check if the browser actually has mixpanel loaded and there are no blockers with respect to the user's device
       */
      mixpanel?.get_distinct_id();
      /**
       * other verification checks:
       *      1. even if mixpanel is loaded, the user may not want to be tracked, and to be safe legally, it is best
       *      to respect this as well, since even if we know that the user does not know that they may still be tracked,
       *      mixpanel is able to detect this user setting, and so we should respect this 'Do Not Track' user setting.
       *      This is because mixpanel init can accept a config {ignore_dnt: true}, and it will ignore the user's request
       *      to not be tracked.
       */
      const hasNotOptedOutTracking = !mixpanel.has_opted_out_tracking();
      verdict = true && hasNotOptedOutTracking;
    } catch (err) {}
    return verdict;
  }
}
