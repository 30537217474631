<div class="shared-layout-container">
  <ng-template #ndrBatteryOutlet></ng-template>
  <div class="shared-layout-container__section-two">
    <div class="sidebar">
      <button
        class="sidebar__button"
        *ngFor="let navigationButton of navigationButtons"
        (click)="onMenuItemClick(navigationButton)"
        [disabled]="currentRoute.includes(navigationButton.routerLink[0])"
      >
        <img loading="lazy" class="sidebar__button-icon" [src]="navigationButton.iconPath" alt="" />
        <p
          class="sidebar__button-text"
          [ngClass]="{
            'sidebar__button-text--tab-selected': currentRoute.includes(
              navigationButton.routerLink[0]
            )
          }"
        >
          {{ navigationButton.tabName | translate }}
        </p>
        <p *ngIf="navigationButton.announcement" class="announcement-style body1--bold">
          {{ navigationButton.announcement | translate }}
        </p>
      </button>
    </div>
    <div class="page-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
