/* eslint-disable import/no-cycle */
import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { NetDeliveryRateResolver } from '../net-deliver-rate-resolver';

@Component({
  styleUrls: ['net-delivery-rate.component.scss'],
  templateUrl: 'net-delivery-rate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgClass, TranslateModule],
})
export class NetDeliveryRateComponent {
  public levels: Array<number>;

  public assetsPath: string;

  public fullName: string;

  public activeMarket: string;

  currentCountry = country;

  constructor(
    public netDeliveryRateResolver: NetDeliveryRateResolver,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    this.levels = [];
    this._createLevels();
    this.assetsPath = 'assets/img/';
  }

  private _createLevels(): void {
    for (let i = 0; i < this.netDeliveryRateResolver.maxBatteryLevels; i++) {
      this.levels.push(i);
    }
  }

  ngOnInit(): void {
    this.fullName = user.fullName;
    this._logMixpanelEventUseCase.execute({
      eventName: 'wallet_ndr_battery_view',
      payload: { ndr_percentage: this.netDeliveryRateResolver.ndrValue },
    });
  }

  public impliedIntentionToExploreNDR(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'wallet_ndr_battery_click',
      payload: { ndr_percentage: this.netDeliveryRateResolver.ndrValue },
    });
  }
}
