import { hazelnut } from '@mentoor.io/hazelnut-client';
import { environment } from 'environment';
import packageInfo from 'package.json';
import { user } from '../../user/data';

hazelnut.init({
  apiKey: environment.HAZELNUT_API_KEY,
  captureUncaughtErrors: true,
  environment: process.env.NODE_ENV as any,
  sourcemap: process.env.NODE_ENV === 'production',
  version: packageInfo.version,
  get user() {
    if (!user.isLoggedIn) {
      return {
        uiSessionId: user.uiSessionKey,
      };
    }

    return {
      id: user.id,
      uiSessionId: user.uiSessionKey,
    };
  },
});
