<div class="footer_wrapper">
  <div class="footer_wrapper__logo">
    <img
      class="footer_wrapper__logo__img"
      src="{{ assetsFolder + 'taager-log.svg' }}"
      alt="taager logo"
    />
  </div>

  <div class="footer_wrapper__links">
    <a
      href="https://taager.bamboohr.com/careers"
      class="footer_wrapper__links__link body2--bold"
      target="_blank"
      (click)="onCareersClick()"
      aria-label="taager jobs recruitee"
      >{{ 'LANDING.FOOTER.LINK_3' | translate }}</a
    >
    <a
      routerLink="{{ appURLs.TERMS_CONDITIONS_URL }}"
      class="footer_wrapper__links__link body2--bold"
      (click)="onTermsAndCondtionsClick()"
      aria-label="taager terms and conditions"
      >{{ 'LANDING.FOOTER.LINK_1' | translate }}</a
    >
  </div>

  <div class="footer_wrapper__socials">
    <span class="body2--bold footer_wrapper__socials__instruction">{{
      socialsLinkInstructionalText | translate
    }}</span>
    <div class="footer_wrapper__socials__links">
      <a
        *ngFor="let socialLink of socialLinks"
        href="{{ socialLink.link }}"
        target="”_blank”"
        (click)="onSocialLinkClicked(socialLink.platform)"
        aria-label="taager social media links"
      >
        <img src="{{ assetsFolder + socialLink.iconURL }}" alt="{{ socialLink.alt }}" />
      </a>
    </div>
  </div>

  <a
    class="footer_wrapper__download"
    href="{{ appURLs.ANDROID_APP_URL }}"
    target="_blank"
    (click)="onAndroidDowloadNowClicked()"
    aria-label="download taager mobile app"
  >
    <img
      class="footer_wrapper__download__icon"
      src="{{ assetsFolder + 'download.svg' }}"
      alt="download taager mobile app"
    />
    <div class="footer_wrapper__download__action">
      <div class="footer_wrapper__download__action__text">
        <span class="body2--medium">{{ 'LANDING.FOOTER.TEXT_1' | translate }}</span>
      </div>
      <img
        class="footer_wrapper__download__action__google-play"
        src="{{ assetsFolder + 'google-play-icon.svg' }}"
        alt="taager google play"
      />
    </div>
  </a>

  <div class="footer_wrapper__copyright body2--bold">
    {{ copyrightLabel | translate }} &copy; {{ copyrightYear }}
  </div>
</div>
