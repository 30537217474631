import { http } from '@features/drivers/data';
import { AuthUseCaseResolver } from '@features/user/domain';
import { hazelnut } from '@mentoor.io/hazelnut-client';
import cache from '@mongez/cache';
import { GenericObject } from '@mongez/reinforcements';
import { currentRoute, navigateTo } from '@presentation/shared/router';
import { LOGIN_URL, REDIRECT_URL } from 'app/presentation/shared/constants';
import { parseError } from '../shared/error';

http.events.onError((response) => {
  // make sure to logout the user if the response status is 401
  // then redirect the user to the login page
  if (response.status === 401) {
    const route = currentRoute();
    cache.set(REDIRECT_URL, currentRoute());

    if (!route.includes(LOGIN_URL)) {
      AuthUseCaseResolver.logoutUseCase();
      navigateTo(LOGIN_URL);
    }
  }

  const payload: GenericObject = {
    response: {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    },
    request: {
      url: response.config.url,
      method: response.config.method,
      params: response.config.params,
    },
  };

  hazelnut.error(new Error(parseError(response)), payload);
});
