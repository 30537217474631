import { Router } from '@angular/router';
import { createAtom } from '@mongez/atom';
import events, { EventSubscription } from '@mongez/events';
import { GenericObject } from '@mongez/reinforcements';

type RouterAtom = {
  route: string;
  queryParams: GenericObject;
  router: Router;
};

export const routerAtom = createAtom<RouterAtom>({
  key: 'router',
  default: {
    route: '',
    queryParams: {},
  },
});

/**
 * Navigate to the given route
 */
export function navigateTo(route: string, queryParams?: GenericObject): void {
  routerAtom.merge({
    route,
    queryParams: queryParams || {},
  });
}

/**
 * Get the current route
 */
export function currentRoute(): string {
  return routerAtom.get('router')?.url || '/';
}

/**
 * Watch current route after changed
 */
export function onRouteChanged(callback: (route: string) => void): EventSubscription {
  return events.subscribe('router.changed', callback);
}

/**
 * Watch current route on start changing
 */
export function onRouteChanging(callback: (route: string) => void): EventSubscription {
  return events.subscribe('router.changing', callback);
}
