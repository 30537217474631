import { country } from '@features/country/data';
import { CountriesUseCaseResolver, Country } from '@features/country/domain';
import { currentRoute } from '@presentation/shared/router';

export function updateAndNavigateToSelectedCountry(passedCountry: Country): void {
  const currentCountryIso2 = passedCountry.isoCode2.toLowerCase();
  const currentPath = currentRoute();
  // Update the selected market use case and then navigate to the new URL
  country.set(passedCountry);

  CountriesUseCaseResolver.updateSelectedMarketUseCase(passedCountry.isoCode3).then(() => {
    const newUrl = `/${currentCountryIso2}${currentPath}`;

    // because the base path in Angular is only declared at app initialization, which has the country code
    // so we can not make a soft navigation to the new URL
    // that's why we need to perform a hard navigation to the new URL instead
    window.location.replace(newUrl);
  });
}
