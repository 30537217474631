import { HttpErrorResponse } from '@angular/common/http';
import { trans } from '@mongez/localization';
import { toastError } from '../toast';

/**
 * This function will do its best to parse the error message coming from an API
 */
export function parseError(error: any): string {
  if (error instanceof HttpErrorResponse) {
    // Handle Angular response
    if (error.error?.description) return error.error.description;

    if (error.error?.message) return error.error.message;

    if (error.error?.msg) return error.error.msg;

    if (error.message) return error.message;

    if (typeof error.error === 'string') return error.error;

    if (error.status === 404) {
      return trans('notFound');
    }

    return trans('somethingWentWrong');
  }

  if (error?.response) {
    // Axios response
    if (error.response?.data?.message) return error.response.data.message;

    if (error.response?.data?.msg) return error.response.data.msg;

    if (error.response?.data?.description) return error.response.data.description;

    if (error.response?.data?.error) return error.response.data.error;

    if (error.response.status === 404) {
      return trans('notFound');
    }
  }

  if (error instanceof Error) {
    return error.message;
  }

  return error;
}

/**
 * Parse and show error message in toast
 */
export function catchError(error: any): void {
  toastError(parseError(error));
}
