<button
  mat-button
  [matMenuTriggerFor]="countriesMenu"
  class="countries-dropdown-btn"
  *ngIf="selectedCountry"
  [ngClass]="darkModeClass$ | async"
>
  <p class="countries-dropdown-btn__ship-to caption1--regular">
    {{ trans('HEADER.COUNTRIES_DROPDOWN.BUTTON_TEXT') }}
  </p>
  <div class="countries-dropdown-btn__selected-country">
    <img
      loading="lazy"
      [src]="selectedCountry.flag"
      alt="country's image"
      class="countries-dropdown-btn__selected-country__icon"
    />
    <p class="countries-dropdown-btn__selected-country__name caption1--medium">
      {{ trans(selectedCountry.isoCode3) }}
    </p>
  </div>
  <i class="icon icon-header-icons-chevron-down countries-dropdown-btn__dropdown-icon"></i>
</button>
<mat-menu class="countries-mat-menu" #countriesMenu="matMenu" xPosition="before">
  <button
    mat-menu-item
    *ngFor="let country of countries"
    class="country-btn"
    [ngClass]="{ 'flex-row-reverse': isLTR }"
    (click)="selectCountry(country)"
  >
    <img
      loading="lazy"
      [src]="country.flag"
      alt="{{ country.name }}'s image"
      class="country-btn__icon"
    />
    <span class="country-btn__name body2--bold">{{ trans(country.isoCode3) }}</span>
  </button>
</mat-menu>
