import { Mapper } from 'src/app/core/base/mapper';
import { FailedOrderModel } from 'src/app/core/domain/order/failed-orders.model';
import { FAILED_ORDERS_REASON_MAP } from 'src/app/data/repositories/order/mappers/failed-orders-reason-mapper';
import { FailedOrderEntity } from '../entities/failed-orders.entity';
import { dukanChecker } from './failed-order-dukan-checker';
import { FAILED_ORDERS_SOURCE_MAP } from './failed-orders-source-mapper';

export class FailedOrderMapper extends Mapper<FailedOrderEntity, FailedOrderModel> {
  mapFrom(param: FailedOrderEntity): FailedOrderModel {
    return {
      createdAt: param.createdAt,
      phoneNumber: param.phoneNumber,
      phoneNumber2: param.phoneNumber2,
      receiverName: param.receiverName,
      province: param.province,
      country: param.country,
      notes: param.notes,
      orderReceivedBy:
        dukanChecker(param.orderReceivedBy!) ||
        FAILED_ORDERS_SOURCE_MAP.get(param.orderReceivedBy!) ||
        'FAILED_ORDERS.SOURCES.OTHER',
      failureReasonMessage: param.failureReasonMessage,
      failureReasonCode:
        FAILED_ORDERS_REASON_MAP.get(param.failureReasonCode) || 'FAILED_ORDERS.REASONS.OTHER',
      address: param.address,
      totalPrice: param.totalPrice,
      totalProfit: param.totalProfit,
    };
  }

  mapTo(param: FailedOrderModel): FailedOrderEntity {
    return {
      createdAt: param.createdAt,
      phoneNumber: param.phoneNumber,
      receiverName: param.receiverName,
      phoneNumber2: param.phoneNumber2,
      province: param.province,
      country: param.country,
      notes: param.notes,
      orderReceivedBy: param.orderReceivedBy,
      failureReasonMessage: param.failureReasonMessage,
      failureReasonCode: param.failureReasonCode,
      address: param.address,
      totalPrice: param.totalPrice,
      totalProfit: param.totalProfit,
    };
  }
}
