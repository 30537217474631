import { inject } from '@angular/core';
import { mergeMap, Observable, of } from 'rxjs';
import { UserRepository } from 'src/app/core/repositories/user.repository';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { FEATURE_FLAGS } from '../constants';
import { ResponsiveService } from '../services/responsive.service';

export const showCustomerEffortSupportUtility = (): (() => Observable<boolean>) => {
  const getFeatureFlaguseCase = inject(GetFeatureFlagUsecase);
  const userRepositoryV2 = inject(UserRepository);
  const responsiveService = inject(ResponsiveService);

  return (): Observable<boolean> =>
    getFeatureFlaguseCase
      .execute(FEATURE_FLAGS.SHOW_CUSTOMER_EFFORT_SUPPORT)
      .pipe(
        mergeMap((flagIsOn) =>
          flagIsOn &&
          userRepositoryV2.getUser() &&
          responsiveService.returnDeviceCategory() !== 'mobile'
            ? of(true)
            : of(false),
        ),
      );
};
