import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { countriesList, country } from '@features/country/data';
import { Country } from '@features/country/domain';
import { updateAndNavigateToSelectedCountry } from '@presentation/country/utils';
import { SetSelectedProductsPageExperienceUseCase } from 'app/core/usecases/products/set-selected-products-page-experience.usecase';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { map, Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { CheckUserNewExperienceEligibilityUseCase } from 'src/app/core/usecases/products/check-user-new-experience-eligibility.usecase';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';

@Component({
  selector: 'app-countries-dropdown',
  templateUrl: './countries-dropdown.component.html',
  styleUrls: ['./countries-dropdown.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatMenuModule, NgFor, NgClass, AsyncPipe],
})
export class CountriesDropdownComponent extends LocalizedComponent {
  public countries: Country[] = countriesList.list;

  public selectedCountry = country.data;

  public darkModeClass$: Observable<string>;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private _setSelectedProductsPageExperienceUseCase: SetSelectedProductsPageExperienceUseCase,
    private _checkUserNewExperienceEligibilityUseCase: CheckUserNewExperienceEligibilityUseCase,
    private _themeService: ThemeService,
  ) {
    super();
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  public selectCountry(passedCountry: Country): void {
    if (passedCountry.isoCode2 !== this.selectedCountry?.isoCode2) {
      updateAndNavigateToSelectedCountry(passedCountry);
      this._logMixpanelEventUseCase.execute({
        eventName: 'change_country',
        payload: {
          'Country Code': passedCountry.isoCode3,
        },
      });
      // TODO: refactor
      this._setSelectedProductsPageExperienceUseCase.execute(
        this._checkUserNewExperienceEligibilityUseCase.execute(),
      );
    }
  }
}
